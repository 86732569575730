import {CapacitorConfig} from '@capacitor/cli';

const config: CapacitorConfig = {
  appId: 'com.lidodelivery',
  appName: 'Lido Delivery',
  webDir: '../lido-delivery.api/wwwroot',
  server: {
    androidScheme: 'http',
  }
};

export default config;
